import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createMessage } from 'src/service/messageApi';
import { da } from 'date-fns/locale';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { notifySuccess, notifyErrorEmpty } from 'src/service/notificationService';
import { useNavigate } from 'react-router-dom';

function formatDate(inputDate) {
    // Créer un objet Date à partir de la chaîne de date d'entrée
    let date = new Date(inputDate);
  
    // Extraire le jour, le mois et l'année de l'objet Date
    let jour = date.getDate();
    let mois = date.getMonth() + 1; // Les mois commencent à partir de zéro dans JavaScript, donc ajouter 1
    let annee = date.getFullYear(); // Obtenez les deux derniers chiffres de l'année
  
    // Ajouter un zéro au début si le jour ou le mois est inférieur à 10
    if (jour < 10) {
      jour = "0" + jour;
    }
    if (mois < 10) {
      mois = "0" + mois;
    }
  
    // Retourner la date formatée en DD-MM-YY
    return `${jour}-${mois}-${annee}`;
  }

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProductCardMatching(annonces) {
  const [annonceNumber, setAnnonceNumber] = React.useState(0)  
  const [totalAnnonce, setTotalAnnonce] = React.useState(annonces.annonces.length)
  const [expanded, setExpanded] = React.useState(false);
  const [message, setMessage] = React.useState("")
  const [endMatching, setEndMatching] = React.useState(true)
  const [alertEmail, setAlertEmail] = React.useState(false)

  let navigate = useNavigate();

  useEffect(() => {
    console.log(annonces.annonces)
    console.log(totalAnnonce)
  }, [])

  const urlImage = process.env.REACT_APP_PUBLIC_URL + "/images/annonces/default/" + annonces.annonces[annonceNumber].category.defaultImage;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleNotifySuccess = () => {
    navigate('/dashboard/products/trouves');
  }

  const sendMessage = () => {
    notifySuccess('Email envoyé', handleNotifySuccess)
  }

  const nextMatching = () => {
    if(annonceNumber + 1 === totalAnnonce) {
        setEndMatching(false)
    } else {
        setAnnonceNumber(annonceNumber + 1)
    }
  }

  const resetMatching = () => {
    setAnnonceNumber(0)
    setEndMatching(true)
  }

  const alertMatching = () => {
    setAlertEmail(true)
  }

  return (
    <>
    {endMatching ? (
    <Card sx={{ maxWidth: 345 }}>
        <CardHeader
            title={annonces.annonces[annonceNumber].category.name}
            subheader={formatDate(annonces.annonces[annonceNumber].createdAt)}
        />
        <CardMedia
            component="img"
            height="194"
            image={urlImage}
            alt="Paella dish"
        />
        <CardContent>
            <Typography variant="body2" color="text.secondary">
                <ul>
                    <li><b>Lieu: </b>{annonces.annonces[annonceNumber].ville}</li>
                    <li><b>Date: </b>{formatDate(annonces.annonces[annonceNumber].createdAt)}</li>
                    <li><b>Détail(s): </b>
                        <ul>
                            {annonces.annonces[annonceNumber].annonceAttributes.map(attribute => {
                                if(attribute.isSecret === false) {
                                    return <li><b>{attribute.attribute}: </b> {attribute.attributeValue}</li>
                                }
                            })}
                        </ul>
                    </li>
                </ul>
            </Typography>
        </CardContent>
        <CardActions disableSpacing>
            <IconButton aria-label="add to favorites" onClick={nextMatching}>
            <CloseIcon />
            </IconButton>

            <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            >
            <FavoriteIcon />
            </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
            <Typography paragraph>Envoyer un message au propriétaire:</Typography>
            <Typography paragraph>
                L'utilisateur qui à posté la demande d'objet perdu, reçevra un formulaire l'invitant à se rendre au lieu de sa perte. Ou il pourra remplir un formulaire en ligne lui permmetant de reçevoir l'objet par la poste.
            </Typography>
            <Button variant="contained" onClick={sendMessage}>
                Envoyer le message
            </Button>
            </CardContent>
            </Collapse>
        </Card>
        ) : (
            <>
                {alertEmail &&
                    <Alert severity="success">
                        <AlertTitle>Alerte activée !</AlertTitle>
                    </Alert>
                }
                <br/>
                <p>Vous avez passé en revue les annonces d'objets trouvés similaires à votre objet perdu. Vous pouvez les revisionner à tout moment:</p>
                <Button variant="contained" onClick={resetMatching}>
                    Revoir les annonces similaires
                </Button>      
                <br /><br />
                {/*<p>Vous pouvez faire une recherche dans vos objets trouvés:</p>
                <Button variant="contained" onClick={alertMatching}>
                    Reçevoir une alerte
                </Button>*/}  
            </>
        )}
    </>
    
  );
}