import React, { useContext, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import ProductsLost from './pages/ProductsLost'
import DashboardApp from './pages/DashboardApp';
import Publier from './sections/@dashboard/publier/Publier';
import AuthContext from './contexts/AuthContext';
import Stockage from './sections/@dashboard/stockage/Stockage';
import { is } from 'date-fns/locale';
import AuthAPI from './service/authAPI';
import Messages from './pages/Messages';
import BoxDetail from "./sections/@dashboard/stockage/BoxDetail";
import Statistics from './pages/Statistics';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuthenticated, setIsAuthenticated, setUser } = useContext(AuthContext);

  setIsAuthenticated(AuthAPI.isAuthenticated());

  return useRoutes([
    {
      path: '/dashboard',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate replace to="/login" />,
      children: [
        { path: 'app', element: <Publier /> },
        { path: 'messages', element: <Messages /> },
        { path: 'products/:annonceID', element: isAuthenticated ? <Products /> : <Navigate replace to="/login" /> },
        {
          path: 'products/trouves',
          element: isAuthenticated ? <Products status="TROUVÉ" /> : <Navigate replace to="/login" />,
        },
        {
          path: 'products/perdus',
          element: isAuthenticated ? <ProductsLost status="PERDU" /> : <Navigate replace to="/login" />,
        },
        {
          path: 'products/restitues',
          element: isAuthenticated ? <Products status="RESTITUÉ" /> : <Navigate replace to="/login" />,
        },
        {
          path: 'products/aexpedie',
          element: isAuthenticated ? <Products status="A_EXPEDIER" /> : <Navigate replace to="/login" />,
        },
        {
          path: 'products/payes',
          element: isAuthenticated ? <Products status="PAYÉ" /> : <Navigate replace to="/login" />,
        },
        {
          path: 'products/envoyes',
          element: isAuthenticated ? <Products status="ENVOYÉ" /> : <Navigate replace to="/login" />,
        },

        { path: 'stockage', element: <Stockage /> },
        { path: 'stockage/:id', element: <BoxDetail />},
        { path: 'publish', element: <Publier /> },
        { path: 'statistics', element: <Statistics /> },
      ],
    },
    {
      path: '/',

      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/publish" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}