import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, FormControlLabel } from "@mui/material";
import { createStockage } from "src/service/stockageApi";

export const AddBoxDialog = ({ addBox, setAddBox }) => {
  const [open, setOpen] = useState(false);
  const [boxName, setBoxName] = useState("");
  const [boxPostData, setBoxPostData] = useState([]);
  const [cat, setCat] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateCategories = (e) => {
    setCat(e.target.checked);
    
  };

  useEffect(() => {
    setBoxPostData({
      year: 2022,
      month: 4,
      boxName: boxName,
      motherBox: true,
    });
  }, [boxName]);

  const handleAddBox = async () => {
    handleClose();
    try {
    
      const response = await createStockage(cat, boxPostData);
      setAddBox(response.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Nouvelle Boite
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter une Boite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour ajouter une boite, veuillez entrer le nom de la boite.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom de la boite"
            fullWidth
            variant="filled"
            onChange={(e) => setBoxName(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Créer des sous boites par catégories"
            onChange={handleCreateCategories}
          />

          <Button onClick={handleAddBox} variant="filled">
            Créer la boite
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
