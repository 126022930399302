import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chat from 'src/components/Chat/Chat';
import Adetails from './Adetails';
import { styled } from '@mui/material/styles';
import UpdateAds from './UpdateAds';
import PropTypes, { object } from 'prop-types';
import AdsTracking from 'src/components/AdsTracking/AdsTracking';
import PaiementTracking from 'src/components/PaiementTracking/PaiementTracking';
import axios from "axios";
import ProductCardMatching from './ProductCardMatching';
import TextField from '@mui/material/TextField';
import { notifySuccess, notifyErrorEmpty } from 'src/service/notificationService';
import { useNavigate } from 'react-router-dom';
import { updateAnnonce } from 'src/service/annonceApi';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function AnnonceDetails({ selectedAnnonce, setSelectedAnnonce, categories, updateUserAds }) {
  const [value, setValue] = useState(0);
  const [selectedAnnonceStatus, setSelectedAnnonceStatus] = useState(selectedAnnonce.status);

  const [matchingData, setMatchingData] = useState(null);
  const [Loading, setLoading] = useState(true)

  let navigate = useNavigate();

  function formatStringForHTTPRequest(inputString) {
    let stringWithoutSpaces = inputString.replace(/ /g, "%20");
    
    let stringWithoutAccents = stringWithoutSpaces.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    return stringWithoutAccents;
  }

  useEffect(async () => {
    let annonceCategoryId = selectedAnnonce.category.id
    let annonceAttributes = selectedAnnonce.annonceAttributes

    let url = ""
    annonceAttributes.map(doc => {
      url += "&annonceAttributes.attribute%5B%5D=" + formatStringForHTTPRequest(doc.attribute)
    })

    annonceAttributes.map(doc => {
      url += "&annonceAttributes.attributeValue%5B%5D=" + formatStringForHTTPRequest(doc.attributeValue)
    })

    url += "&origin=TROUVE&category.id=" + annonceCategoryId + "&status=TROUVE"

    await axios.get(process.env.REACT_APP_PUBLIC_URL + '/api/annonces?professionalId=' + window.localStorage.getItem('customerId')).then(res => {
        const objets = res.data.filter(object => object.status === "PERDU")
        console.log(objets)
        setMatchingData(objets)
      });
    
    setLoading(false)
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNotifySuccess = () => {
    navigate('/dashboard/products/trouves');
  }

  const sendMessage = () => {
    notifySuccess('Email envoyé', handleNotifySuccess)
  }

  const handleUpdateAds = async () => {
    try {
      //setIsLoading(true);
      await updateAnnonce(selectedAnnonce.id, {status: "RESTITUÉ"}).then((res) => {
        notifySuccess('Objet restitué !', handleNotifySuccess)
        navigate('/dashboard/products/restitues');
      })
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Adetails annonce={selectedAnnonce} />
        </Grid>

        <Grid item xs={12} md={6} sx={{alignItems: "center", display: "grid"}}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{margin: "50px auto 0 auto"}} variant="fullWidth">
              <Tab label="Matching" {...a11yProps(0)} />
              <Tab label="Modifier" {...a11yProps(1)} />
              <Tab label="Suivi de paiement" {...a11yProps(2)} />
              {selectedAnnonceStatus !== "TROUVÉ" && (
                  <Tab label="Suivi de livraison" {...a11yProps(3)} />
              )}
          </Tabs>
          <Stack spacing={2}>
            <CustomTabPanel value={value} index={0}>
                {selectedAnnonceStatus === "PERDU" ? (
                  <>
                    {Loading ? (
                    <p>Matching</p>
                    ):(
                      <>
                        <ProductCardMatching annonces={matchingData} />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {selectedAnnonce.status !== "RESTITUÉ" ? (
                      <>
                        <p>Le matching n'est pas disponible pour cette annonce.</p>
                        <p>Vous pouvez restituer cet objet en main propre:</p>
                        <Button variant="contained" onClick={handleUpdateAds}>
                          Objet restitué en main propre
                        </Button>  
                        <br /><br />
                        <p>Ou envoyer un formulaire de restitution par la poste par email à un utilisateur:</p>
                        <TextField type='email' label='email'></TextField><br /><br />
                        <Button variant="contained" onClick={sendMessage}>
                          Envoyer le formulaire
                        </Button>                        
                      </>
                    ) : (
                      <p>Le matching n'est pas disponible pour cette annonce, car l'objet est déjà restitué</p>
                    )
                    }
                  </>
                )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <UpdateAds selectedAnnonce={selectedAnnonce} setSelectedAnnonce={setSelectedAnnonce} categories={categories} updateUserAds={updateUserAds}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <PaiementTracking annonce={selectedAnnonce} />
            </CustomTabPanel>
            {selectedAnnonceStatus !== "TROUVÉ" && (
              <>
                <CustomTabPanel value={value} index={3}>
                  <AdsTracking annonce={selectedAnnonce} />
                </CustomTabPanel>
              </>
            )}
          </Stack>
        </Grid>

      </Grid>
    </>
  );
}
